




































































import { isEqual } from 'lodash'
import { Bind, Debounce } from 'lodash-decorators'
import { Component, Mixins } from 'vue-property-decorator'

// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// components
import TableFooter from '@/components/_uikit/TableFooter.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import Tag from '@/components/_uikit/Tag.vue'
// store
import ManagerCoursesModule from '@/store/modules/manager/courses'
import DictionaryModule from '@/store/modules/dictionary'
// types
import { CourseShortResource, IManagerCoursesFilter, IManagerCoursesFilterType } from '@/store/types'
// utils
import { tableFooterOptions } from '@/utils/constants'
import { formatDate } from '@/utils/functions'

@Component({
  components: {
    Select,
    TableFooter,
    Tag,
    TextInput,
  },
})
export default class CoursesTable extends Mixins(NotifyMixin) {
  private footerOptions = tableFooterOptions

  private coursesStatuses = [
    { name: 'Активные', value: '1' },
    { name: 'Неопубликованные', value: '0' },
  ]

  private get subjects() {
    return DictionaryModule.subjects
  }

  private get groupsTypes() {
    return DictionaryModule.groupTypes
  }

  private get filter () {
    return ManagerCoursesModule.coursesFilter
  }

  private set filter (filter: IManagerCoursesFilter) {
    if (!isEqual(filter, this.filter) || this.courses.length === 0) {
      ManagerCoursesModule.setCoursesFilter(filter)
      this.fetchCourses()
    }
  }

  private get courses () {
    return ManagerCoursesModule.courses.data
  }

  private get pagination () {
    return ManagerCoursesModule.courses.meta
  }

  private get headers () {
    return [
      { cellClass: 'cell-width-8', sortable: false, text: '#', value: 'id' },
      { text: 'Название', value: 'title' },
      { cellClass: 'cell-hidden-md', class: 'cell-hidden-md', text: 'Аннотация', value: 'annotation' },
      { cellClass: 'cell-width-20', text: 'Дата прохождения', value: 'educationPeriod' },
      { cellClass: 'cell-width-12', text: 'Публичная продажа', value: 'statusSale' },
    ]
  }

  private formatDate (educationPeriod: string[]) {
    return educationPeriod[1]
      ? `${formatDate(educationPeriod[0], 'dd MMM yyyy')} - ${formatDate(educationPeriod[1], 'dd MMM yyyy')}`
      : formatDate(educationPeriod[0], 'dd MMM yyyy')
  }

  private handleFilter (field: IManagerCoursesFilterType, value: never) {
    this.filter = {
      ...this.filter,
      [field]: value,
      page: 1,
    }
  }

  @Bind
  @Debounce(300)
  private fetchCourses () {
    ManagerCoursesModule.fetchCourses()
      .catch(this.notifyError)
  }

  private handleRowClick (course: CourseShortResource) {
    this.$router.push({ name: 'manager.control.courses.item', params: { courseID: course.id.toString() } })
  }
}
