




















import { MetaInfo } from 'vue-meta'
import { Component } from 'vue-property-decorator'

import CoursesTable from '@/components/tables/CoursesTable.vue'
import PermissionsMixin from '@/mixins/PermissionsMixin'

@Component({
  components: { CoursesTable },
})
export default class CoursesList extends PermissionsMixin {
  private metaInfo (): MetaInfo {
    return {
      title: 'Список курсов',
    }
  }
}
